/*
 * Icon arrays
 */

export default {
  "regular": {
    "address-book": true,
    "address-card": true,
    "angry": true,
    "arrow-alt-circle-down": true,
    "arrow-alt-circle-left": true,
    "arrow-alt-circle-right": true,
    "arrow-alt-circle-up": true,
    "bell": true,
    "bell-slash": true,
    "bookmark": true,
    "building": true,
    "calendar": true,
    "calendar-alt": true,
    "calendar-check": true,
    "calendar-minus": true,
    "calendar-plus": true,
    "calendar-times": true,
    "caret-square-down": true,
    "caret-square-left": true,
    "caret-square-right": true,
    "caret-square-up": true,
    "chart-bar": true,
    "check-circle": true,
    "check-square": true,
    "circle": true,
    "clipboard": true,
    "clock": true,
    "clone": true,
    "closed-captioning": true,
    "comment": true,
    "comment-alt": true,
    "comment-dots": true,
    "comments": true,
    "compass": true,
    "copy": true,
    "copyright": true,
    "credit-card": true,
    "dizzy": true,
    "dot-circle": true,
    "edit": true,
    "envelope": true,
    "envelope-open": true,
    "eye": true,
    "eye-slash": true,
    "file": true,
    "file-alt": true,
    "file-archive": true,
    "file-audio": true,
    "file-code": true,
    "file-excel": true,
    "file-image": true,
    "file-pdf": true,
    "file-powerpoint": true,
    "file-video": true,
    "file-word": true,
    "flag": true,
    "flushed": true,
    "folder": true,
    "folder-open": true,
    "font-awesome-logo-full": true,
    "frown": true,
    "frown-open": true,
    "futbol": true,
    "gem": true,
    "grimace": true,
    "grin": true,
    "grin-alt": true,
    "grin-beam": true,
    "grin-beam-sweat": true,
    "grin-hearts": true,
    "grin-squint": true,
    "grin-squint-tears": true,
    "grin-stars": true,
    "grin-tears": true,
    "grin-tongue": true,
    "grin-tongue-squint": true,
    "grin-tongue-wink": true,
    "grin-wink": true,
    "hand-lizard": true,
    "hand-paper": true,
    "hand-peace": true,
    "hand-point-down": true,
    "hand-point-left": true,
    "hand-point-right": true,
    "hand-point-up": true,
    "hand-pointer": true,
    "hand-rock": true,
    "hand-scissors": true,
    "hand-spock": true,
    "handshake": true,
    "hdd": true,
    "heart": true,
    "hospital": true,
    "hourglass": true,
    "id-badge": true,
    "id-card": true,
    "image": true,
    "images": true,
    "keyboard": true,
    "kiss": true,
    "kiss-beam": true,
    "kiss-wink-heart": true,
    "laugh": true,
    "laugh-beam": true,
    "laugh-squint": true,
    "laugh-wink": true,
    "lemon": true,
    "life-ring": true,
    "lightbulb": true,
    "list-alt": true,
    "map": true,
    "meh": true,
    "meh-blank": true,
    "meh-rolling-eyes": true,
    "minus-square": true,
    "money-bill-alt": true,
    "moon": true,
    "newspaper": true,
    "object-group": true,
    "object-ungroup": true,
    "paper-plane": true,
    "pause-circle": true,
    "play-circle": true,
    "plus-square": true,
    "question-circle": true,
    "registered": true,
    "sad-cry": true,
    "sad-tear": true,
    "save": true,
    "share-square": true,
    "smile": true,
    "smile-beam": true,
    "smile-wink": true,
    "snowflake": true,
    "square": true,
    "star": true,
    "star-half": true,
    "sticky-note": true,
    "stop-circle": true,
    "sun": true,
    "surprise": true,
    "thumbs-down": true,
    "thumbs-up": true,
    "times-circle": true,
    "tired": true,
    "trash-alt": true,
    "user": true,
    "user-circle": true,
    "window-close": true,
    "window-maximize": true,
    "window-minimize": true,
    "window-restore": true
  },
  "solid": {
    "ad": true,
    "address-book": true,
    "address-card": true,
    "adjust": true,
    "air-freshener": true,
    "align-center": true,
    "align-justify": true,
    "align-left": true,
    "align-right": true,
    "allergies": true,
    "ambulance": true,
    "american-sign-language-interpreting": true,
    "anchor": true,
    "angle-double-down": true,
    "angle-double-left": true,
    "angle-double-right": true,
    "angle-double-up": true,
    "angle-down": true,
    "angle-left": true,
    "angle-right": true,
    "angle-up": true,
    "angry": true,
    "ankh": true,
    "apple-alt": true,
    "archive": true,
    "archway": true,
    "arrow-alt-circle-down": true,
    "arrow-alt-circle-left": true,
    "arrow-alt-circle-right": true,
    "arrow-alt-circle-up": true,
    "arrow-circle-down": true,
    "arrow-circle-left": true,
    "arrow-circle-right": true,
    "arrow-circle-up": true,
    "arrow-down": true,
    "arrow-left": true,
    "arrow-right": true,
    "arrow-up": true,
    "arrows-alt": true,
    "arrows-alt-h": true,
    "arrows-alt-v": true,
    "assistive-listening-systems": true,
    "asterisk": true,
    "at": true,
    "atlas": true,
    "atom": true,
    "audio-description": true,
    "award": true,
    "baby": true,
    "baby-carriage": true,
    "backspace": true,
    "backward": true,
    "bacon": true,
    "bacteria": true,
    "bacterium": true,
    "bahai": true,
    "balance-scale": true,
    "balance-scale-left": true,
    "balance-scale-right": true,
    "ban": true,
    "band-aid": true,
    "barcode": true,
    "bars": true,
    "baseball-ball": true,
    "basketball-ball": true,
    "bath": true,
    "battery-empty": true,
    "battery-full": true,
    "battery-half": true,
    "battery-quarter": true,
    "battery-three-quarters": true,
    "bed": true,
    "beer": true,
    "bell": true,
    "bell-slash": true,
    "bezier-curve": true,
    "bible": true,
    "bicycle": true,
    "biking": true,
    "binoculars": true,
    "biohazard": true,
    "birthday-cake": true,
    "blender": true,
    "blender-phone": true,
    "blind": true,
    "blog": true,
    "bold": true,
    "bolt": true,
    "bomb": true,
    "bone": true,
    "bong": true,
    "book": true,
    "book-dead": true,
    "book-medical": true,
    "book-open": true,
    "book-reader": true,
    "bookmark": true,
    "border-all": true,
    "border-none": true,
    "border-style": true,
    "bowling-ball": true,
    "box": true,
    "box-open": true,
    "box-tissue": true,
    "boxes": true,
    "braille": true,
    "brain": true,
    "bread-slice": true,
    "briefcase": true,
    "briefcase-medical": true,
    "broadcast-tower": true,
    "broom": true,
    "brush": true,
    "bug": true,
    "building": true,
    "bullhorn": true,
    "bullseye": true,
    "burn": true,
    "bus": true,
    "bus-alt": true,
    "business-time": true,
    "calculator": true,
    "calendar": true,
    "calendar-alt": true,
    "calendar-check": true,
    "calendar-day": true,
    "calendar-minus": true,
    "calendar-plus": true,
    "calendar-times": true,
    "calendar-week": true,
    "camera": true,
    "camera-retro": true,
    "campground": true,
    "candy-cane": true,
    "cannabis": true,
    "capsules": true,
    "car": true,
    "car-alt": true,
    "car-battery": true,
    "car-crash": true,
    "car-side": true,
    "caravan": true,
    "caret-down": true,
    "caret-left": true,
    "caret-right": true,
    "caret-square-down": true,
    "caret-square-left": true,
    "caret-square-right": true,
    "caret-square-up": true,
    "caret-up": true,
    "carrot": true,
    "cart-arrow-down": true,
    "cart-plus": true,
    "cash-register": true,
    "cat": true,
    "certificate": true,
    "chair": true,
    "chalkboard": true,
    "chalkboard-teacher": true,
    "charging-station": true,
    "chart-area": true,
    "chart-bar": true,
    "chart-line": true,
    "chart-pie": true,
    "check": true,
    "check-circle": true,
    "check-double": true,
    "check-square": true,
    "cheese": true,
    "chess": true,
    "chess-bishop": true,
    "chess-board": true,
    "chess-king": true,
    "chess-knight": true,
    "chess-pawn": true,
    "chess-queen": true,
    "chess-rook": true,
    "chevron-circle-down": true,
    "chevron-circle-left": true,
    "chevron-circle-right": true,
    "chevron-circle-up": true,
    "chevron-down": true,
    "chevron-left": true,
    "chevron-right": true,
    "chevron-up": true,
    "child": true,
    "church": true,
    "circle": true,
    "circle-notch": true,
    "city": true,
    "clinic-medical": true,
    "clipboard": true,
    "clipboard-check": true,
    "clipboard-list": true,
    "clock": true,
    "clone": true,
    "closed-captioning": true,
    "cloud": true,
    "cloud-download-alt": true,
    "cloud-meatball": true,
    "cloud-moon": true,
    "cloud-moon-rain": true,
    "cloud-rain": true,
    "cloud-showers-heavy": true,
    "cloud-sun": true,
    "cloud-sun-rain": true,
    "cloud-upload-alt": true,
    "cocktail": true,
    "code": true,
    "code-branch": true,
    "coffee": true,
    "cog": true,
    "cogs": true,
    "coins": true,
    "columns": true,
    "comment": true,
    "comment-alt": true,
    "comment-dollar": true,
    "comment-dots": true,
    "comment-medical": true,
    "comment-slash": true,
    "comments": true,
    "comments-dollar": true,
    "compact-disc": true,
    "compass": true,
    "compress": true,
    "compress-alt": true,
    "compress-arrows-alt": true,
    "concierge-bell": true,
    "cookie": true,
    "cookie-bite": true,
    "copy": true,
    "copyright": true,
    "couch": true,
    "credit-card": true,
    "crop": true,
    "crop-alt": true,
    "cross": true,
    "crosshairs": true,
    "crow": true,
    "crown": true,
    "crutch": true,
    "cube": true,
    "cubes": true,
    "cut": true,
    "database": true,
    "deaf": true,
    "democrat": true,
    "desktop": true,
    "dharmachakra": true,
    "diagnoses": true,
    "dice": true,
    "dice-d20": true,
    "dice-d6": true,
    "dice-five": true,
    "dice-four": true,
    "dice-one": true,
    "dice-six": true,
    "dice-three": true,
    "dice-two": true,
    "digital-tachograph": true,
    "directions": true,
    "disease": true,
    "divide": true,
    "dizzy": true,
    "dna": true,
    "dog": true,
    "dollar-sign": true,
    "dolly": true,
    "dolly-flatbed": true,
    "donate": true,
    "door-closed": true,
    "door-open": true,
    "dot-circle": true,
    "dove": true,
    "download": true,
    "drafting-compass": true,
    "dragon": true,
    "draw-polygon": true,
    "drum": true,
    "drum-steelpan": true,
    "drumstick-bite": true,
    "dumbbell": true,
    "dumpster": true,
    "dumpster-fire": true,
    "dungeon": true,
    "edit": true,
    "egg": true,
    "eject": true,
    "ellipsis-h": true,
    "ellipsis-v": true,
    "envelope": true,
    "envelope-open": true,
    "envelope-open-text": true,
    "envelope-square": true,
    "equals": true,
    "eraser": true,
    "ethernet": true,
    "euro-sign": true,
    "exchange-alt": true,
    "exclamation": true,
    "exclamation-circle": true,
    "exclamation-triangle": true,
    "expand": true,
    "expand-alt": true,
    "expand-arrows-alt": true,
    "external-link-alt": true,
    "external-link-square-alt": true,
    "eye": true,
    "eye-dropper": true,
    "eye-slash": true,
    "fan": true,
    "fast-backward": true,
    "fast-forward": true,
    "faucet": true,
    "fax": true,
    "feather": true,
    "feather-alt": true,
    "female": true,
    "fighter-jet": true,
    "file": true,
    "file-alt": true,
    "file-archive": true,
    "file-audio": true,
    "file-code": true,
    "file-contract": true,
    "file-csv": true,
    "file-download": true,
    "file-excel": true,
    "file-export": true,
    "file-image": true,
    "file-import": true,
    "file-invoice": true,
    "file-invoice-dollar": true,
    "file-medical": true,
    "file-medical-alt": true,
    "file-pdf": true,
    "file-powerpoint": true,
    "file-prescription": true,
    "file-signature": true,
    "file-upload": true,
    "file-video": true,
    "file-word": true,
    "fill": true,
    "fill-drip": true,
    "film": true,
    "filter": true,
    "fingerprint": true,
    "fire": true,
    "fire-alt": true,
    "fire-extinguisher": true,
    "first-aid": true,
    "fish": true,
    "fist-raised": true,
    "flag": true,
    "flag-checkered": true,
    "flag-usa": true,
    "flask": true,
    "flushed": true,
    "folder": true,
    "folder-minus": true,
    "folder-open": true,
    "folder-plus": true,
    "font": true,
    "font-awesome-logo-full": true,
    "football-ball": true,
    "forward": true,
    "frog": true,
    "frown": true,
    "frown-open": true,
    "funnel-dollar": true,
    "futbol": true,
    "gamepad": true,
    "gas-pump": true,
    "gavel": true,
    "gem": true,
    "genderless": true,
    "ghost": true,
    "gift": true,
    "gifts": true,
    "glass-cheers": true,
    "glass-martini": true,
    "glass-martini-alt": true,
    "glass-whiskey": true,
    "glasses": true,
    "globe": true,
    "globe-africa": true,
    "globe-americas": true,
    "globe-asia": true,
    "globe-europe": true,
    "golf-ball": true,
    "gopuram": true,
    "graduation-cap": true,
    "greater-than": true,
    "greater-than-equal": true,
    "grimace": true,
    "grin": true,
    "grin-alt": true,
    "grin-beam": true,
    "grin-beam-sweat": true,
    "grin-hearts": true,
    "grin-squint": true,
    "grin-squint-tears": true,
    "grin-stars": true,
    "grin-tears": true,
    "grin-tongue": true,
    "grin-tongue-squint": true,
    "grin-tongue-wink": true,
    "grin-wink": true,
    "grip-horizontal": true,
    "grip-lines": true,
    "grip-lines-vertical": true,
    "grip-vertical": true,
    "guitar": true,
    "h-square": true,
    "hamburger": true,
    "hammer": true,
    "hamsa": true,
    "hand-holding": true,
    "hand-holding-heart": true,
    "hand-holding-medical": true,
    "hand-holding-usd": true,
    "hand-holding-water": true,
    "hand-lizard": true,
    "hand-middle-finger": true,
    "hand-paper": true,
    "hand-peace": true,
    "hand-point-down": true,
    "hand-point-left": true,
    "hand-point-right": true,
    "hand-point-up": true,
    "hand-pointer": true,
    "hand-rock": true,
    "hand-scissors": true,
    "hand-sparkles": true,
    "hand-spock": true,
    "hands": true,
    "hands-helping": true,
    "hands-wash": true,
    "handshake": true,
    "handshake-alt-slash": true,
    "handshake-slash": true,
    "hanukiah": true,
    "hard-hat": true,
    "hashtag": true,
    "hat-cowboy": true,
    "hat-cowboy-side": true,
    "hat-wizard": true,
    "hdd": true,
    "head-side-cough": true,
    "head-side-cough-slash": true,
    "head-side-mask": true,
    "head-side-virus": true,
    "heading": true,
    "headphones": true,
    "headphones-alt": true,
    "headset": true,
    "heart": true,
    "heart-broken": true,
    "heartbeat": true,
    "helicopter": true,
    "highlighter": true,
    "hiking": true,
    "hippo": true,
    "history": true,
    "hockey-puck": true,
    "holly-berry": true,
    "home": true,
    "horse": true,
    "horse-head": true,
    "hospital": true,
    "hospital-alt": true,
    "hospital-symbol": true,
    "hospital-user": true,
    "hot-tub": true,
    "hotdog": true,
    "hotel": true,
    "hourglass": true,
    "hourglass-end": true,
    "hourglass-half": true,
    "hourglass-start": true,
    "house-damage": true,
    "house-user": true,
    "hryvnia": true,
    "i-cursor": true,
    "ice-cream": true,
    "icicles": true,
    "icons": true,
    "id-badge": true,
    "id-card": true,
    "id-card-alt": true,
    "igloo": true,
    "image": true,
    "images": true,
    "inbox": true,
    "indent": true,
    "industry": true,
    "infinity": true,
    "info": true,
    "info-circle": true,
    "italic": true,
    "jedi": true,
    "joint": true,
    "journal-whills": true,
    "kaaba": true,
    "key": true,
    "keyboard": true,
    "khanda": true,
    "kiss": true,
    "kiss-beam": true,
    "kiss-wink-heart": true,
    "kiwi-bird": true,
    "landmark": true,
    "language": true,
    "laptop": true,
    "laptop-code": true,
    "laptop-house": true,
    "laptop-medical": true,
    "laugh": true,
    "laugh-beam": true,
    "laugh-squint": true,
    "laugh-wink": true,
    "layer-group": true,
    "leaf": true,
    "lemon": true,
    "less-than": true,
    "less-than-equal": true,
    "level-down-alt": true,
    "level-up-alt": true,
    "life-ring": true,
    "lightbulb": true,
    "link": true,
    "lira-sign": true,
    "list": true,
    "list-alt": true,
    "list-ol": true,
    "list-ul": true,
    "location-arrow": true,
    "lock": true,
    "lock-open": true,
    "long-arrow-alt-down": true,
    "long-arrow-alt-left": true,
    "long-arrow-alt-right": true,
    "long-arrow-alt-up": true,
    "low-vision": true,
    "luggage-cart": true,
    "lungs": true,
    "lungs-virus": true,
    "magic": true,
    "magnet": true,
    "mail-bulk": true,
    "male": true,
    "map": true,
    "map-marked": true,
    "map-marked-alt": true,
    "map-marker": true,
    "map-marker-alt": true,
    "map-pin": true,
    "map-signs": true,
    "marker": true,
    "mars": true,
    "mars-double": true,
    "mars-stroke": true,
    "mars-stroke-h": true,
    "mars-stroke-v": true,
    "mask": true,
    "medal": true,
    "medkit": true,
    "meh": true,
    "meh-blank": true,
    "meh-rolling-eyes": true,
    "memory": true,
    "menorah": true,
    "mercury": true,
    "meteor": true,
    "microchip": true,
    "microphone": true,
    "microphone-alt": true,
    "microphone-alt-slash": true,
    "microphone-slash": true,
    "microscope": true,
    "minus": true,
    "minus-circle": true,
    "minus-square": true,
    "mitten": true,
    "mobile": true,
    "mobile-alt": true,
    "money-bill": true,
    "money-bill-alt": true,
    "money-bill-wave": true,
    "money-bill-wave-alt": true,
    "money-check": true,
    "money-check-alt": true,
    "monument": true,
    "moon": true,
    "mortar-pestle": true,
    "mosque": true,
    "motorcycle": true,
    "mountain": true,
    "mouse": true,
    "mouse-pointer": true,
    "mug-hot": true,
    "music": true,
    "network-wired": true,
    "neuter": true,
    "newspaper": true,
    "not-equal": true,
    "notes-medical": true,
    "object-group": true,
    "object-ungroup": true,
    "oil-can": true,
    "om": true,
    "otter": true,
    "outdent": true,
    "pager": true,
    "paint-brush": true,
    "paint-roller": true,
    "palette": true,
    "pallet": true,
    "paper-plane": true,
    "paperclip": true,
    "parachute-box": true,
    "paragraph": true,
    "parking": true,
    "passport": true,
    "pastafarianism": true,
    "paste": true,
    "pause": true,
    "pause-circle": true,
    "paw": true,
    "peace": true,
    "pen": true,
    "pen-alt": true,
    "pen-fancy": true,
    "pen-nib": true,
    "pen-square": true,
    "pencil-alt": true,
    "pencil-ruler": true,
    "people-arrows": true,
    "people-carry": true,
    "pepper-hot": true,
    "percent": true,
    "percentage": true,
    "person-booth": true,
    "phone": true,
    "phone-alt": true,
    "phone-slash": true,
    "phone-square": true,
    "phone-square-alt": true,
    "phone-volume": true,
    "photo-video": true,
    "piggy-bank": true,
    "pills": true,
    "pizza-slice": true,
    "place-of-worship": true,
    "plane": true,
    "plane-arrival": true,
    "plane-departure": true,
    "plane-slash": true,
    "play": true,
    "play-circle": true,
    "plug": true,
    "plus": true,
    "plus-circle": true,
    "plus-square": true,
    "podcast": true,
    "poll": true,
    "poll-h": true,
    "poo": true,
    "poo-storm": true,
    "poop": true,
    "portrait": true,
    "pound-sign": true,
    "power-off": true,
    "pray": true,
    "praying-hands": true,
    "prescription": true,
    "prescription-bottle": true,
    "prescription-bottle-alt": true,
    "print": true,
    "procedures": true,
    "project-diagram": true,
    "pump-medical": true,
    "pump-soap": true,
    "puzzle-piece": true,
    "qrcode": true,
    "question": true,
    "question-circle": true,
    "quidditch": true,
    "quote-left": true,
    "quote-right": true,
    "quran": true,
    "radiation": true,
    "radiation-alt": true,
    "rainbow": true,
    "random": true,
    "receipt": true,
    "record-vinyl": true,
    "recycle": true,
    "redo": true,
    "redo-alt": true,
    "registered": true,
    "remove-format": true,
    "reply": true,
    "reply-all": true,
    "republican": true,
    "restroom": true,
    "retweet": true,
    "ribbon": true,
    "ring": true,
    "road": true,
    "robot": true,
    "rocket": true,
    "route": true,
    "rss": true,
    "rss-square": true,
    "ruble-sign": true,
    "ruler": true,
    "ruler-combined": true,
    "ruler-horizontal": true,
    "ruler-vertical": true,
    "running": true,
    "rupee-sign": true,
    "sad-cry": true,
    "sad-tear": true,
    "satellite": true,
    "satellite-dish": true,
    "save": true,
    "school": true,
    "screwdriver": true,
    "scroll": true,
    "sd-card": true,
    "search": true,
    "search-dollar": true,
    "search-location": true,
    "search-minus": true,
    "search-plus": true,
    "seedling": true,
    "server": true,
    "shapes": true,
    "share": true,
    "share-alt": true,
    "share-alt-square": true,
    "share-square": true,
    "shekel-sign": true,
    "shield-alt": true,
    "shield-virus": true,
    "ship": true,
    "shipping-fast": true,
    "shoe-prints": true,
    "shopping-bag": true,
    "shopping-basket": true,
    "shopping-cart": true,
    "shower": true,
    "shuttle-van": true,
    "sign": true,
    "sign-in-alt": true,
    "sign-language": true,
    "sign-out-alt": true,
    "signal": true,
    "signature": true,
    "sim-card": true,
    "sink": true,
    "sitemap": true,
    "skating": true,
    "skiing": true,
    "skiing-nordic": true,
    "skull": true,
    "skull-crossbones": true,
    "slash": true,
    "sleigh": true,
    "sliders-h": true,
    "smile": true,
    "smile-beam": true,
    "smile-wink": true,
    "smog": true,
    "smoking": true,
    "smoking-ban": true,
    "sms": true,
    "snowboarding": true,
    "snowflake": true,
    "snowman": true,
    "snowplow": true,
    "soap": true,
    "socks": true,
    "solar-panel": true,
    "sort": true,
    "sort-alpha-down": true,
    "sort-alpha-down-alt": true,
    "sort-alpha-up": true,
    "sort-alpha-up-alt": true,
    "sort-amount-down": true,
    "sort-amount-down-alt": true,
    "sort-amount-up": true,
    "sort-amount-up-alt": true,
    "sort-down": true,
    "sort-numeric-down": true,
    "sort-numeric-down-alt": true,
    "sort-numeric-up": true,
    "sort-numeric-up-alt": true,
    "sort-up": true,
    "spa": true,
    "space-shuttle": true,
    "spell-check": true,
    "spider": true,
    "spinner": true,
    "splotch": true,
    "spray-can": true,
    "square": true,
    "square-full": true,
    "square-root-alt": true,
    "stamp": true,
    "star": true,
    "star-and-crescent": true,
    "star-half": true,
    "star-half-alt": true,
    "star-of-david": true,
    "star-of-life": true,
    "step-backward": true,
    "step-forward": true,
    "stethoscope": true,
    "sticky-note": true,
    "stop": true,
    "stop-circle": true,
    "stopwatch": true,
    "stopwatch-20": true,
    "store": true,
    "store-alt": true,
    "store-alt-slash": true,
    "store-slash": true,
    "stream": true,
    "street-view": true,
    "strikethrough": true,
    "stroopwafel": true,
    "subscript": true,
    "subway": true,
    "suitcase": true,
    "suitcase-rolling": true,
    "sun": true,
    "superscript": true,
    "surprise": true,
    "swatchbook": true,
    "swimmer": true,
    "swimming-pool": true,
    "synagogue": true,
    "sync": true,
    "sync-alt": true,
    "syringe": true,
    "table": true,
    "table-tennis": true,
    "tablet": true,
    "tablet-alt": true,
    "tablets": true,
    "tachometer-alt": true,
    "tag": true,
    "tags": true,
    "tape": true,
    "tasks": true,
    "taxi": true,
    "teeth": true,
    "teeth-open": true,
    "temperature-high": true,
    "temperature-low": true,
    "tenge": true,
    "terminal": true,
    "text-height": true,
    "text-width": true,
    "th": true,
    "th-large": true,
    "th-list": true,
    "theater-masks": true,
    "thermometer": true,
    "thermometer-empty": true,
    "thermometer-full": true,
    "thermometer-half": true,
    "thermometer-quarter": true,
    "thermometer-three-quarters": true,
    "thumbs-down": true,
    "thumbs-up": true,
    "thumbtack": true,
    "ticket-alt": true,
    "times": true,
    "times-circle": true,
    "tint": true,
    "tint-slash": true,
    "tired": true,
    "toggle-off": true,
    "toggle-on": true,
    "toilet": true,
    "toilet-paper": true,
    "toilet-paper-slash": true,
    "toolbox": true,
    "tools": true,
    "tooth": true,
    "torah": true,
    "torii-gate": true,
    "tractor": true,
    "trademark": true,
    "traffic-light": true,
    "trailer": true,
    "train": true,
    "tram": true,
    "transgender": true,
    "transgender-alt": true,
    "trash": true,
    "trash-alt": true,
    "trash-restore": true,
    "trash-restore-alt": true,
    "tree": true,
    "trophy": true,
    "truck": true,
    "truck-loading": true,
    "truck-monster": true,
    "truck-moving": true,
    "truck-pickup": true,
    "tshirt": true,
    "tty": true,
    "tv": true,
    "umbrella": true,
    "umbrella-beach": true,
    "underline": true,
    "undo": true,
    "undo-alt": true,
    "universal-access": true,
    "university": true,
    "unlink": true,
    "unlock": true,
    "unlock-alt": true,
    "upload": true,
    "user": true,
    "user-alt": true,
    "user-alt-slash": true,
    "user-astronaut": true,
    "user-check": true,
    "user-circle": true,
    "user-clock": true,
    "user-cog": true,
    "user-edit": true,
    "user-friends": true,
    "user-graduate": true,
    "user-injured": true,
    "user-lock": true,
    "user-md": true,
    "user-minus": true,
    "user-ninja": true,
    "user-nurse": true,
    "user-plus": true,
    "user-secret": true,
    "user-shield": true,
    "user-slash": true,
    "user-tag": true,
    "user-tie": true,
    "user-times": true,
    "users": true,
    "users-cog": true,
    "users-slash": true,
    "utensil-spoon": true,
    "utensils": true,
    "vector-square": true,
    "venus": true,
    "venus-double": true,
    "venus-mars": true,
    "vial": true,
    "vials": true,
    "video": true,
    "video-slash": true,
    "vihara": true,
    "virus": true,
    "virus-slash": true,
    "viruses": true,
    "voicemail": true,
    "volleyball-ball": true,
    "volume-down": true,
    "volume-mute": true,
    "volume-off": true,
    "volume-up": true,
    "vote-yea": true,
    "vr-cardboard": true,
    "walking": true,
    "wallet": true,
    "warehouse": true,
    "water": true,
    "wave-square": true,
    "weight": true,
    "weight-hanging": true,
    "wheelchair": true,
    "wifi": true,
    "wind": true,
    "window-close": true,
    "window-maximize": true,
    "window-minimize": true,
    "window-restore": true,
    "wine-bottle": true,
    "wine-glass": true,
    "wine-glass-alt": true,
    "won-sign": true,
    "wrench": true,
    "x-ray": true,
    "yen-sign": true,
    "yin-yang": true
  },
  "brands": {
    "500px": true,
    "accessible-icon": true,
    "accusoft": true,
    "acquisitions-incorporated": true,
    "adn": true,
    "adobe": true,
    "adversal": true,
    "affiliatetheme": true,
    "airbnb": true,
    "algolia": true,
    "alipay": true,
    "amazon": true,
    "amazon-pay": true,
    "amilia": true,
    "android": true,
    "angellist": true,
    "angrycreative": true,
    "angular": true,
    "app-store": true,
    "app-store-ios": true,
    "apper": true,
    "apple": true,
    "apple-pay": true,
    "artstation": true,
    "asymmetrik": true,
    "atlassian": true,
    "audible": true,
    "autoprefixer": true,
    "avianex": true,
    "aviato": true,
    "aws": true,
    "bandcamp": true,
    "battle-net": true,
    "behance": true,
    "behance-square": true,
    "bimobject": true,
    "bitbucket": true,
    "bitcoin": true,
    "bity": true,
    "black-tie": true,
    "blackberry": true,
    "blogger": true,
    "blogger-b": true,
    "bluetooth": true,
    "bluetooth-b": true,
    "bootstrap": true,
    "btc": true,
    "buffer": true,
    "buromobelexperte": true,
    "buy-n-large": true,
    "buysellads": true,
    "canadian-maple-leaf": true,
    "cc-amazon-pay": true,
    "cc-amex": true,
    "cc-apple-pay": true,
    "cc-diners-club": true,
    "cc-discover": true,
    "cc-jcb": true,
    "cc-mastercard": true,
    "cc-paypal": true,
    "cc-stripe": true,
    "cc-visa": true,
    "centercode": true,
    "centos": true,
    "chrome": true,
    "chromecast": true,
    "cloudscale": true,
    "cloudsmith": true,
    "cloudversify": true,
    "codepen": true,
    "codiepie": true,
    "confluence": true,
    "connectdevelop": true,
    "contao": true,
    "cotton-bureau": true,
    "cpanel": true,
    "creative-commons": true,
    "creative-commons-by": true,
    "creative-commons-nc": true,
    "creative-commons-nc-eu": true,
    "creative-commons-nc-jp": true,
    "creative-commons-nd": true,
    "creative-commons-pd": true,
    "creative-commons-pd-alt": true,
    "creative-commons-remix": true,
    "creative-commons-sa": true,
    "creative-commons-sampling": true,
    "creative-commons-sampling-plus": true,
    "creative-commons-share": true,
    "creative-commons-zero": true,
    "critical-role": true,
    "css3": true,
    "css3-alt": true,
    "cuttlefish": true,
    "d-and-d": true,
    "d-and-d-beyond": true,
    "dailymotion": true,
    "dashcube": true,
    "deezer": true,
    "delicious": true,
    "deploydog": true,
    "deskpro": true,
    "dev": true,
    "deviantart": true,
    "dhl": true,
    "diaspora": true,
    "digg": true,
    "digital-ocean": true,
    "discord": true,
    "discourse": true,
    "dochub": true,
    "docker": true,
    "draft2digital": true,
    "dribbble": true,
    "dribbble-square": true,
    "dropbox": true,
    "drupal": true,
    "dyalog": true,
    "earlybirds": true,
    "ebay": true,
    "edge": true,
    "edge-legacy": true,
    "elementor": true,
    "ello": true,
    "ember": true,
    "empire": true,
    "envira": true,
    "erlang": true,
    "ethereum": true,
    "etsy": true,
    "evernote": true,
    "expeditedssl": true,
    "facebook": true,
    "facebook-f": true,
    "facebook-messenger": true,
    "facebook-square": true,
    "fantasy-flight-games": true,
    "fedex": true,
    "fedora": true,
    "figma": true,
    "firefox": true,
    "firefox-browser": true,
    "first-order": true,
    "first-order-alt": true,
    "firstdraft": true,
    "flickr": true,
    "flipboard": true,
    "fly": true,
    "font-awesome": true,
    "font-awesome-alt": true,
    "font-awesome-flag": true,
    "font-awesome-logo-full": true,
    "fonticons": true,
    "fonticons-fi": true,
    "fort-awesome": true,
    "fort-awesome-alt": true,
    "forumbee": true,
    "foursquare": true,
    "free-code-camp": true,
    "freebsd": true,
    "fulcrum": true,
    "galactic-republic": true,
    "galactic-senate": true,
    "get-pocket": true,
    "gg": true,
    "gg-circle": true,
    "git": true,
    "git-alt": true,
    "git-square": true,
    "github": true,
    "github-alt": true,
    "github-square": true,
    "gitkraken": true,
    "gitlab": true,
    "gitter": true,
    "glide": true,
    "glide-g": true,
    "gofore": true,
    "goodreads": true,
    "goodreads-g": true,
    "google": true,
    "google-drive": true,
    "google-pay": true,
    "google-play": true,
    "google-plus": true,
    "google-plus-g": true,
    "google-plus-square": true,
    "google-wallet": true,
    "gratipay": true,
    "grav": true,
    "gripfire": true,
    "grunt": true,
    "gulp": true,
    "hacker-news": true,
    "hacker-news-square": true,
    "hackerrank": true,
    "hips": true,
    "hire-a-helper": true,
    "hooli": true,
    "hornbill": true,
    "hotjar": true,
    "houzz": true,
    "html5": true,
    "hubspot": true,
    "ideal": true,
    "imdb": true,
    "instagram": true,
    "instagram-square": true,
    "intercom": true,
    "internet-explorer": true,
    "invision": true,
    "ioxhost": true,
    "itch-io": true,
    "itunes": true,
    "itunes-note": true,
    "java": true,
    "jedi-order": true,
    "jenkins": true,
    "jira": true,
    "joget": true,
    "joomla": true,
    "js": true,
    "js-square": true,
    "jsfiddle": true,
    "kaggle": true,
    "keybase": true,
    "keycdn": true,
    "kickstarter": true,
    "kickstarter-k": true,
    "korvue": true,
    "laravel": true,
    "lastfm": true,
    "lastfm-square": true,
    "leanpub": true,
    "less": true,
    "line": true,
    "linkedin": true,
    "linkedin-in": true,
    "linode": true,
    "linux": true,
    "lyft": true,
    "magento": true,
    "mailchimp": true,
    "mandalorian": true,
    "markdown": true,
    "mastodon": true,
    "maxcdn": true,
    "mdb": true,
    "medapps": true,
    "medium": true,
    "medium-m": true,
    "medrt": true,
    "meetup": true,
    "megaport": true,
    "mendeley": true,
    "microblog": true,
    "microsoft": true,
    "mix": true,
    "mixcloud": true,
    "mixer": true,
    "mizuni": true,
    "modx": true,
    "monero": true,
    "napster": true,
    "neos": true,
    "nimblr": true,
    "node": true,
    "node-js": true,
    "npm": true,
    "ns8": true,
    "nutritionix": true,
    "odnoklassniki": true,
    "odnoklassniki-square": true,
    "old-republic": true,
    "opencart": true,
    "openid": true,
    "opera": true,
    "optin-monster": true,
    "orcid": true,
    "osi": true,
    "page4": true,
    "pagelines": true,
    "palfed": true,
    "patreon": true,
    "paypal": true,
    "penny-arcade": true,
    "periscope": true,
    "phabricator": true,
    "phoenix-framework": true,
    "phoenix-squadron": true,
    "php": true,
    "pied-piper": true,
    "pied-piper-alt": true,
    "pied-piper-hat": true,
    "pied-piper-pp": true,
    "pied-piper-square": true,
    "pinterest": true,
    "pinterest-p": true,
    "pinterest-square": true,
    "playstation": true,
    "product-hunt": true,
    "pushed": true,
    "python": true,
    "qq": true,
    "quinscape": true,
    "quora": true,
    "r-project": true,
    "raspberry-pi": true,
    "ravelry": true,
    "react": true,
    "reacteurope": true,
    "readme": true,
    "rebel": true,
    "red-river": true,
    "reddit": true,
    "reddit-alien": true,
    "reddit-square": true,
    "redhat": true,
    "renren": true,
    "replyd": true,
    "researchgate": true,
    "resolving": true,
    "rev": true,
    "rocketchat": true,
    "rockrms": true,
    "rust": true,
    "safari": true,
    "salesforce": true,
    "sass": true,
    "schlix": true,
    "scribd": true,
    "searchengin": true,
    "sellcast": true,
    "sellsy": true,
    "servicestack": true,
    "shirtsinbulk": true,
    "shopify": true,
    "shopware": true,
    "simplybuilt": true,
    "sistrix": true,
    "sith": true,
    "sketch": true,
    "skyatlas": true,
    "skype": true,
    "slack": true,
    "slack-hash": true,
    "slideshare": true,
    "snapchat": true,
    "snapchat-ghost": true,
    "snapchat-square": true,
    "soundcloud": true,
    "sourcetree": true,
    "speakap": true,
    "speaker-deck": true,
    "spotify": true,
    "squarespace": true,
    "stack-exchange": true,
    "stack-overflow": true,
    "stackpath": true,
    "staylinked": true,
    "steam": true,
    "steam-square": true,
    "steam-symbol": true,
    "sticker-mule": true,
    "strava": true,
    "stripe": true,
    "stripe-s": true,
    "studiovinari": true,
    "stumbleupon": true,
    "stumbleupon-circle": true,
    "superpowers": true,
    "supple": true,
    "suse": true,
    "swift": true,
    "symfony": true,
    "teamspeak": true,
    "telegram": true,
    "telegram-plane": true,
    "tencent-weibo": true,
    "the-red-yeti": true,
    "themeco": true,
    "themeisle": true,
    "think-peaks": true,
    "tiktok": true,
    "trade-federation": true,
    "trello": true,
    "tripadvisor": true,
    "tumblr": true,
    "tumblr-square": true,
    "twitch": true,
    "twitter": true,
    "twitter-square": true,
    "typo3": true,
    "uber": true,
    "ubuntu": true,
    "uikit": true,
    "umbraco": true,
    "uniregistry": true,
    "unity": true,
    "unsplash": true,
    "untappd": true,
    "ups": true,
    "usb": true,
    "usps": true,
    "ussunnah": true,
    "vaadin": true,
    "viacoin": true,
    "viadeo": true,
    "viadeo-square": true,
    "viber": true,
    "vimeo": true,
    "vimeo-square": true,
    "vimeo-v": true,
    "vine": true,
    "vk": true,
    "vnv": true,
    "vuejs": true,
    "waze": true,
    "weebly": true,
    "weibo": true,
    "weixin": true,
    "whatsapp": true,
    "whatsapp-square": true,
    "whmcs": true,
    "wikipedia-w": true,
    "windows": true,
    "wix": true,
    "wizards-of-the-coast": true,
    "wolf-pack-battalion": true,
    "wordpress": true,
    "wordpress-simple": true,
    "wpbeginner": true,
    "wpexplorer": true,
    "wpforms": true,
    "wpressr": true,
    "xbox": true,
    "xing": true,
    "xing-square": true,
    "y-combinator": true,
    "yahoo": true,
    "yammer": true,
    "yandex": true,
    "yandex-international": true,
    "yarn": true,
    "yelp": true,
    "yoast": true,
    "youtube": true,
    "youtube-square": true,
    "zhihu": true
  },
  "simpleline": {
    "action-redo": true,
    "action-undo": true,
    "anchor": true,
    "arrow-down": true,
    "arrow-left": true,
    "arrow-right": true,
    "arrow-up": true,
    "badge": true,
    "bag": true,
    "ban": true,
    "bar-chart": true,
    "basket": true,
    "basket-loaded": true,
    "bell": true,
    "book-open": true,
    "briefcase": true,
    "bubble": true,
    "bubbles": true,
    "bulb": true,
    "calculator": true,
    "calendar": true,
    "call-end": true,
    "call-in": true,
    "call-out": true,
    "camcorder": true,
    "camera": true,
    "check": true,
    "chemistry": true,
    "clock": true,
    "close": true,
    "cloud-download": true,
    "cloud-upload": true,
    "compass": true,
    "control-end": true,
    "control-forward": true,
    "control-pause": true,
    "control-play": true,
    "control-rewind": true,
    "control-start": true,
    "credit-card": true,
    "crop": true,
    "cup": true,
    "cursor": true,
    "cursor-move": true,
    "diamond": true,
    "direction": true,
    "directions": true,
    "disc": true,
    "dislike": true,
    "doc": true,
    "docs": true,
    "drawer": true,
    "drop": true,
    "earphones": true,
    "earphones-alt": true,
    "emoticon-smile": true,
    "energy": true,
    "envelope": true,
    "envelope-letter": true,
    "envelope-open": true,
    "equalizer": true,
    "eye": true,
    "eyeglasses": true,
    "feed": true,
    "film": true,
    "fire": true,
    "flag": true,
    "folder": true,
    "folder-alt": true,
    "frame": true,
    "game-controller": true,
    "ghost": true,
    "globe": true,
    "globe-alt": true,
    "graduation": true,
    "graph": true,
    "grid": true,
    "handbag": true,
    "heart": true,
    "home": true,
    "hourglass": true,
    "info": true,
    "key": true,
    "layers": true,
    "like": true,
    "link": true,
    "list": true,
    "lock": true,
    "lock-open": true,
    "login": true,
    "logout": true,
    "loop": true,
    "magic-wand": true,
    "magnet": true,
    "magnifier": true,
    "magnifier-add": true,
    "magnifier-remove": true,
    "map": true,
    "microphone": true,
    "mouse": true,
    "moustache": true,
    "music-tone": true,
    "music-tone-alt": true,
    "note": true,
    "notebook": true,
    "paper-clip": true,
    "paper-plane": true,
    "pencil": true,
    "picture": true,
    "pie-chart": true,
    "pin": true,
    "plane": true,
    "playlist": true,
    "plus": true,
    "pointer": true,
    "power": true,
    "present": true,
    "printer": true,
    "puzzle": true,
    "question": true,
    "refresh": true,
    "reload": true,
    "rocket": true,
    "screen-desktop": true,
    "screen-smartphone": true,
    "screen-tablet": true,
    "settings": true,
    "share": true,
    "share-alt": true,
    "shield": true,
    "shuffle": true,
    "size-actual": true,
    "size-fullscreen": true,
    "social-dribbble": true,
    "social-dropbox": true,
    "social-facebook": true,
    "social-tumblr": true,
    "social-twitter": true,
    "social-youtube": true,
    "speech": true,
    "speedometer": true,
    "star": true,
    "support": true,
    "symbol-female": true,
    "symbol-male": true,
    "tag": true,
    "target": true,
    "trash": true,
    "trophy": true,
    "umbrella": true,
    "user": true,
    "user-female": true,
    "user-follow": true,
    "user-following": true,
    "user-unfollow": true,
    "users": true,
    "vector": true,
    "volume-1": true,
    "volume-2": true,
    "volume-off": true,
    "wallet": true,
    "wrench": true
  }
}
